<template>
<div>
    <v-system-bar height="50" app color="#fff" dark elevation="4" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);" class="hide-on-md">
        <v-btn color="#fff" @click.stop="toggleSideBar" elevation="0" v-show="isOnCourse" v-if="shouldShowhamburger">
            <v-icon  color="#666666" size="x-large" >mdi mdi-menu</v-icon>
        </v-btn>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="false"></v-app-bar-nav-icon>
        <div>
            <a href="/">
                <img alt="portal Logo"  contain src="../assets/images/cpm-logo-text-line.svg" width="140" />
            </a>
        </div>
        <v-spacer></v-spacer>
        <!-- <div style=" text-align: center;">
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://www.info.tlic.cmu.ac.th/','_blank')"><strong style="fontSize: 12px;">TLIC</strong></v-btn>
        </div> -->
        <div style="text-align: center;">
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://docs.tlic.cmu.ac.th/course-portal-management/','_blank')">
                <img style="color: #727178; padding: 0.2rem;"  src="../assets/icon/manualIcon.svg" />
                <strong style="fontSize: 12px; color: #727178;">Manual</strong></v-btn>
        </div>
        <div style="max-width: fit-content; text-align: center;">
            
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " @click="handleShowSchedule">
                <img style="color: #727178;  padding: 0.2rem; "  src="../assets/icon/calendarIcon.svg" />

            <strong style="fontSize: 12px; color: #727178;">Important Dates</strong></v-btn>
        </div>
        <div style="text-align: center;">
        
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://www.info.tlic.cmu.ac.th/contact-us','_blank')"> 
                <img style="color: #727178;  padding: 0.2rem; width: 22px;"  src="../assets/icon/mailIcon.svg" />

            <strong style="fontSize: 12px; color: #727178;">Contact us</strong></v-btn>
            
        </div>

        <div style="height: 50px; width: 235px; margin-right: -8px;  ">
            <div class="text-right">
                <v-menu 
                   content-class="custom-menu-content"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="closeMenu = attrs['aria-expanded']==='true'? false : true"
                            v-click-outside="handleCloseMenu"
                            style=" background-color: transparent !important; height: 50px; width: 219px; background: #6868AC; border-radius: 0px;"
                        >
                           
                            <v-icon white large  class="ml-0">
                                mdi-account-circle
                            </v-icon>
                            <strong  class="ml-1" style=" font-size: 10px;" v-if="String(userProfile?.name).length > 20">{{ userProfile.name.slice(0, 20)}}...</strong>
                            <strong  class="ml-1" style=" font-size: 10px;" v-else>{{ userProfile.name}}</strong>
                            <img v-if="closeMenu" class="ml-2 mr-1" src="../assets/icon/cross.svg"/>
                            <img v-else  class="ml-2 mr-1" src="../assets/icon/burger.svg"/>
                        </v-btn>
                        
                    </template>
                    <v-list style="background: #6868AC;">
                        <v-list-item
                            :key="0"
                            style="border-bottom:none; color: white"
                            v-if="studentView" 
                        >
                            <v-list-item-title>
                                <v-list-item-subtitle style="color: #FAAC1E; font-size: 10px;"> 
                                    <v-icon style="color: #FAAC1E; font-size: 14px;" >
                                        mdi-account-box-outline
                                    </v-icon> 
                                    Role
                                </v-list-item-subtitle>
                                <v-text style="font-size: 14px;">STUDENT</v-text>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            :key="0"
                            style="border-bottom:none; color: white"
                            v-if="ShowAdmin === 'hide'  && !studentView" 
                        >
                            <v-list-item-title>
                                <v-list-item-subtitle style="color: #FAAC1E; font-size: 10px;"> 
                                    <v-icon style="color: #FAAC1E; font-size: 14px;" >
                                        mdi-account-box-outline
                                    </v-icon> 
                                    Role
                                </v-list-item-subtitle>
                                <v-text style="font-size: 14px;"><img style="color: white; margin-bottom: -8px; margin-left: -0.3rem; padding: 0.3rem;" src="../assets/icon/swap.svg" />TEACHER</v-text>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            :key="1"
                            style="border-bottom:none; color: white"
                            link
                            v-if="ShowAdmin === 'teacher'  && !studentView" to="/"
                            @click="OnClickAdmin"
                        >
                            <v-list-item-title>
                                <v-list-item-subtitle style="color: #FAAC1E; font-size: 10px;"> 
                                    <v-icon style="color: #FAAC1E; font-size: 14px;" >
                                        mdi-account-box-outline
                                    </v-icon> 
                                    Role
                                </v-list-item-subtitle>
                                
                                <v-text style="font-size: 14px;"><img style="color: white; margin-bottom: -8px; margin-left: -0.3rem; padding: 0.3rem;" src="../assets/icon/swap.svg" />TEACHER</v-text>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            :key="6"
                            style="border-bottom:none; color: white"
                            link
                            v-if="ShowAdmin === 'admin' && !studentView " to='/admin-console' 
                            @click="OnClickAdmin"
                        >
                            <v-list-item-title>
                                <v-list-item-subtitle style="color: #FAAC1E; font-size: 10px;"> 
                                    <v-icon style="color: #FAAC1E; font-size: 14px;" >
                                        mdi-account-box-outline
                                    </v-icon> 
                                    Role
                                </v-list-item-subtitle>
                                

                                <v-text style="font-size: 14px;"><img style="color: white; margin-bottom: -8px; margin-left: -0.3rem; padding: 0.3rem;" src="../assets/icon/swap.svg" />ADMIN</v-text>
                            </v-list-item-title>
                        </v-list-item>
                     
                        <v-list-item
                            :key="3"
                            style="border-bottom:none; color: white"
                            link
                            to='/signout'
                        >
                            <v-list-item-title>
                                <v-list-item-subtitle style="color: #FAAC1E;  font-size: 10px;">
                                    <img src="../assets/icon/user-account.svg" style="padding-left: 1.5px; padding-right: 1px; margin-bottom: -3px;"/>
                                    Account
                                </v-list-item-subtitle>
                                
                                <v-text style=" font-size: 14px; " ><img style="color: white; margin-bottom: -8px; margin-left: -0.3rem; padding: 0.3rem;" src="../assets/icon/logout.svg" />Signout</v-text>

                            </v-list-item-title>
                            

                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
          

        </div>

    </v-system-bar>
    <v-system-bar height="60" app color="#ffffff" dark elevation="4" class="show-on-md">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="false"></v-app-bar-nav-icon>
        <v-row class="mt-1">
            <div>
                <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' } " :click="OnClickAdmin()" :to="{path:'/'}">
                    <img alt="portal Logo"  contain src="../assets/images/cpm-logo-text-line.svg" width="140" />
                </v-btn>

            </div>
            <v-spacer></v-spacer>

            <div style="padding: 0.3rem;  height: 60px; width: 200px; margin-right: -8px; margin-top: -4px; background:  linear-gradient(270deg, #765299 -6.19%, #5E50AD 103.53%); text-align: center;">
                <v-row class="d-flex justify-content-center">
                    <v-col class="align-self-center mt-3">
                        <h6 v-if="String(userProfile?.name).length > 20">{{ userProfile.name.slice(0, 20)}}...</h6>
                        <h6 v-else>{{ userProfile.name}}</h6>
                    </v-col>
                    <v-col>
                        <v-menu bottom rounded class="mt">

                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon white large>
                                        mdi-account-circle
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <div style="max-width: 50px;  text-align: center; text-decoration: none; margin-right: 1rem;">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://www.info.tlic.cmu.ac.th/','_blank')"><strong style="fontSize: 12px;">TLIC</strong></v-btn>
                                </div>
                                <div style="max-width: 50px; text-align: center;  margin-right: 2rem;">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://docs.tlic.cmu.ac.th/course-portal-management/','_blank')"><strong style="fontSize: 12px;">Manual</strong></v-btn>
                                </div>
                                <div style="max-width: 60px; text-align: center;  margin-right: 3rem;">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://www.info.tlic.cmu.ac.th/contact-us','_blank')"><strong style="fontSize: 12px;">Contact us</strong></v-btn>
                                </div>

                                <!-- <div style="max-width: 120px; text-align: center; margin-right: 2rem;"  >
                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://enroll.elearning.cmu.ac.th/mango','_blank')" ><strong style="fontSize: 12px;">Mango Sandbox</strong></v-btn>
                  </div> -->

                                <div style="max-width: 120px; text-align: center; margin-right: 2rem;">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " @click="handleShowSchedule"><strong style="fontSize: 12px;">Important Dates</strong></v-btn>
                                </div>

                                <div style="max-width: 50px; text-align: center;  margin-right: 2rem;" v-if="ShowAdmin === 'true'">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " :click="OnClickAdmin()" :to="{path:'/admin-console'}"><strong style="fontSize: 12px;">Admin</strong></v-btn>
                                </div>

                                <div style="max-width: 80px; text-align: center;  margin-right: 2rem;" v-if="ShowAdmin != 'true' && userProfile.jobTitle?.toLowerCase().includes('employee')">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " :click="OnClickAdmin()" :to="{path:'/'}"><strong style="fontSize: 12px;">Teacher</strong></v-btn>
                                </div>
                                <v-list-item-content class="justify-center">
                                    <div class="mx-auto text-center">
                                        <v-btn depressed rounded text to="/signout">
                                            Signout
                                        </v-btn>
                                    </div>
                                </v-list-item-content>
                            </v-card>
                        </v-menu>
                    </v-col>
                </v-row>
            </div>
        </v-row>
        
    </v-system-bar>
    <ModalSchedule :showModal="showSchedule" :dataList="schedule" @onClose="handleShowSchedule" />
</div>
</template>
<style scoped>

.custom-menu-content {
    right: 0px !important;
    top: 50px !important;
    left: auto !important ;
    z-index: 5 !important;
    border-radius : 0px !important;
    min-width: 235px;
}

</style>
<script>
const config = require(`@/config`)
import CryptoJS from 'crypto-js'
import ModalSchedule from '../components/ModalNotification/ModalSchedule.vue'
import dataEventCpm from '../config/dataEventCpm.json'

// import swapIcon from '../assets/icon/swap.svg'
// import { ref } from 'vue';
// const menu = ref(null);

export default {
    name: 'Theheader',
    components: {
        ModalSchedule
    },
    data() {
        return {
            userProfile: {},
            ShowAdmin: 'hide',
            studentView : false , 
            showSchedule: false,
            schedule: dataEventCpm,
            closeMenu: false,
           
        }
    },
    watch: {
        // RouterName(val){
        //   // this.syncStatus = !val
        //   console.log("toggle :>" , val);
        //   this.RouterName = this.$route.name;

        // },
    },

    mounted() {

    },
    created() {
        this.setUserProfile()
        this.OnClickAdmin()
    },
    computed: {
        isOnCourse: {
            get() {
                return this.$store.state.isOnCourse
            },
            set() {
                console.log("isOnCourse")
            }
        },
        shouldShowhamburger() {
            return this.$route.meta.hamburger !== false
        },
    },
    methods: {
        handleCloseMenu(){
            // console.log("check" , data['aria-expanded'])
            this.closeMenu = false
        },
        handleShowSchedule() {
            if (this.showSchedule) {
                this.showSchedule = false
            } else {
                this.showSchedule = true
            }
        },
    
        async OnClickAdmin() {
            // console.log("this.$route.name" , this.$route.name)
            if (this.$route.name.includes("Admin")) {
                this.ShowAdmin = "teacher";
            } else {
                this.ShowAdmin = localStorage.getItem("grantAdmin")?"admin":"hide";
            }

        },
        setUserProfile() {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem("profile"), config.aesKey.key)
            this.userProfile = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
            if(this.userProfile.jobTitle?.toLowerCase().includes('employee')){
                this.studentView = false;
            }else{
                this.studentView = true;
            }
            // this.$store.commit('setUserData', this.userProfile)
            // this.ShowAdmin = this.userProfile.jobTitle?.toLowerCase().includes('employee');
        },
        toggleSideBar() {
            this.$store.state.sideBarIsShow ? this.$store.commit('setSideBarHide') : this.$store.commit('setSideBarShow')
        }
    },
}
</script>
